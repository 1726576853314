import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ChargeService } from '../../core/services/charge.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StepModel } from '../../core/models/step.model';
import { paymentSteps, StepService } from '../../core/services/step.service';
import { Subscription } from 'rxjs';
import { PaymentService, paymentStatuses } from '../../core/services/payment.service';
import { LocaleService } from '../../core/services/locale.service';
import { UtilsService } from '../../core/services/utils.service';
import { ChargeModel } from '../../core/models/charge.model';
import { KYCModel } from '../../core/models/kyc.model';

@Component({
  selector: 'app-charge',
  templateUrl: './charge.component.html',
  styleUrls: ['./charge.component.scss']
})
export class ChargeComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  charge: ChargeModel = new ChargeModel();
  chargeInterval: any;
  chargeTimeLeft = 0;
  currentStep: StepModel | undefined;
  currentDate: Date;
  isMobile = false;

  constructor(
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private stepService: StepService,
    private chargeService: ChargeService,
    private paymentService: PaymentService,
    private utilsService: UtilsService,
    private localeService: LocaleService
  ) {
    // set current date
    this.currentDate = new Date();

    // subscribe to is mobile
    this.subscriptions.push(
      this.utilsService.isMobile$$.subscribe((isMobile) => {
        this.isMobile = isMobile;
      })
    );

    // subscribe to step
    this.subscriptions.push(
      this.stepService.currentStep$$.subscribe((step: StepModel) => {
        if (step != null) {
          this.currentStep = step;
        }
      })
    );

    // get charge details
    const chargeId = this.route.snapshot.paramMap.get('id');
    if (chargeId != null) {
      if (this.chargeService.charge$$.value.charge_id === '') {
        this.chargeService.getCharge(chargeId).subscribe({
          next: (data: ChargeModel) => {
            // set charge
            this.charge = data;

            // check if redirect url exists
            if (this.charge.redirect_url) {
              window.location.href = this.charge.redirect_url;
            } else {
              // hide loader
              this.utilsService.hideLoader();

              // set language
              if (this.localeService.getDefaultLocale() !== this.charge.language) {
                this.localeService.setLocale(this.charge.language);
              }

              // set charge time left
              this.chargeTimeLeft = this.utilsService.timeDifference(
                this.charge.expires_at,
                this.utilsService.getUTCTimestamp()
              );

              // set kyc
              this.paymentService.kyc$$.next(data.kyc ?? new KYCModel());

              // set charge timer
              this.startChargeTimer();

              // check if payment was return as STATIC
              if (this.isStaticPayment()) {
                // check if payment needs transaction double check
                if (this.transactionDoubleCheck()) {
                  // start content loader
                  this.utilsService.showContentLoader();
                  // get url params
                  const urlParams: any = this.utilsService.urlParams$$.value;
                  const transactionId = 'transaction_id' in urlParams ? urlParams['transaction_id'] : null;
                  // set payment timer initial data
                  this.paymentService.paymentTimeLeft$$.next(this.chargeTimeLeft);
                  // start transaction status interval
                  this.paymentService.startTransactionStatusInterval(false, transactionId);
                  // subscribe to payment/transaction status
                  this.paymentService.paymentStatus$$.subscribe((status) => {
                    if (status !== paymentStatuses.IN_PROGRESS) {
                      if (status === paymentStatuses.SUCCESS) {
                        this.stepService.toStep(paymentSteps.PAYMENT_STATIC_SUCCESS);
                      } else {
                        this.stepService.toStep(paymentSteps.PAYMENT_STATIC_ERROR);
                      }
                    }
                  });
                } else {
                  // check if static payment is success
                  if (this.isStaticPaymentSuccess()) {
                    this.stepService.toStep(paymentSteps.PAYMENT_STATIC_SUCCESS);
                  } else {
                    this.stepService.toStep(paymentSteps.PAYMENT_STATIC_ERROR);
                  }
                }
              } else {
                // show payment methods selector
                this.stepService.toStep(paymentSteps.PAYMENT_METHOD_SELECT);
              }
            }
          },
          error: () => {
            this.utilsService.hideLoader();
            this.router.navigate(['/404']);
          },
          complete: () => {
            // hide loader
            // this.utilsService.hideLoader();
          }
        });
      }
    } else {
      this.utilsService.hideLoader();
      this.router.navigate(['/404']);
    }
  }

  ngOnInit() {
    if (!this.isMobile) {
      this.renderer.addClass(document.body, 'gradient');
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  startChargeTimer() {
    this.chargeInterval = setInterval(() => {
      if (this.chargeTimeLeft <= 0) {
        this.pauseChargeTimer();
        this.router.navigate(['/404']);
      } else {
        this.chargeTimeLeft--;
      }
    }, 1000);
  }

  pauseChargeTimer() {
    clearInterval(this.chargeInterval);
  }

  isStaticPayment() {
    const urlParams: any = this.utilsService.urlParams$$.value;
    return 'static_payment' in urlParams && urlParams['static_payment'].toLowerCase() === 'true';
  }

  transactionDoubleCheck() {
    const urlParams: any = this.utilsService.urlParams$$.value;
    return 'transaction_double_check' in urlParams && urlParams['transaction_double_check'].toLowerCase() === 'true';
  }

  isStaticPaymentSuccess() {
    const urlParams: any = this.utilsService.urlParams$$.value;
    return urlParams['payment_status'] === paymentStatuses.SUCCESS && 'transaction_id' in urlParams;
  }
}
