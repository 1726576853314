import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../core/services/utils.service';
import { ChargeService } from '../../../core/services/charge.service';
import { PaymentOptionModel } from '../../../core/models/payment-option.model';
import { PaymentService } from '../../../core/services/payment.service';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit {
  // paymentMethods: PaymentMethodModel[] = [];
  // paymentMethod: PaymentMethodModel;
  paymentOptions: PaymentOptionModel[] = [];

  constructor(
    private utilsService: UtilsService,
    private chargeService: ChargeService,
    private paymentService: PaymentService
  ) {
    this.utilsService.hideContentTerminatePayment();
    this.utilsService.showTerminatePayment();
    // this.paymentMethods = this.chargeService.paymentMethods$$.value;
    // this.paymentMethod = this.chargeService.charge$$.value.payment_method;
    // this.paymentOptions = this.chargeService.charge$$.value["payment_options"];
    this.paymentOptions = this.paymentService.selectedPaymentMethod$$.value.payment_options;
  }

  ngOnInit() {}
}
