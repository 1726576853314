import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StepModel } from '../models/step.model';

export enum paymentSteps {
  PAYMENT_METHOD_SELECT = 'PAYMENT_METHOD_SELECT',
  PAYMENT_OPTIONS = 'PAYMENT_OPTIONS',
  PAYMENT_NETWORKS = 'PAYMENT_NETWORKS',
  PAYMENT_KYC = 'PAYMENT_KYC',
  PAYMENT_PROCESSOR = 'PAYMENT_PROCESSOR',
  PAYMENT_CLAIM_REFUND = 'PAYMENT_CLAIM_REFUND',
  PAYMENT_STATIC_SUCCESS = 'PAYMENT_STATIC_SUCCESS',
  PAYMENT_STATIC_ERROR = 'PAYMENT_STATIC_ERROR',
  PAYMENT_ERROR = 'PAYMENT_ERROR'
}

const STEPS = [
  {
    step: paymentSteps.PAYMENT_METHOD_SELECT,
    previousStep: null,
    isLocked: false
  },
  {
    step: paymentSteps.PAYMENT_OPTIONS,
    previousStep: paymentSteps.PAYMENT_METHOD_SELECT,
    isLocked: false
  },
  {
    step: paymentSteps.PAYMENT_NETWORKS,
    previousStep: paymentSteps.PAYMENT_OPTIONS,
    isLocked: false
  },
  {
    step: paymentSteps.PAYMENT_KYC,
    previousStep: paymentSteps.PAYMENT_OPTIONS,
    isLocked: false
  },
  {
    step: paymentSteps.PAYMENT_PROCESSOR,
    previousStep: paymentSteps.PAYMENT_METHOD_SELECT,
    isLocked: false
  },
  {
    step: paymentSteps.PAYMENT_CLAIM_REFUND,
    previousStep: null,
    isLocked: false
  },
  {
    step: paymentSteps.PAYMENT_STATIC_SUCCESS,
    previousStep: null,
    isLocked: false
  },
  {
    step: paymentSteps.PAYMENT_STATIC_ERROR,
    previousStep: null,
    isLocked: false
  },
  {
    step: paymentSteps.PAYMENT_ERROR,
    previousStep: null,
    isLocked: false
  }
];

@Injectable({
  providedIn: 'root'
})
export class StepService {
  steps$$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>(STEPS);
  // @ts-ignore
  currentStep$$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);
  stepLoading$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  toStep(step: string | null): void {
    this.steps$$.value.forEach((value) => {
      if (value.step === step) {
        this.currentStep$$.next(value);
      }
    });
  }

  updateLoading(loading: boolean) {
    this.stepLoading$$.next(loading);
  }
}
