import { Component, Input, OnInit } from '@angular/core';
import { PaymentOptionModel } from '../../../../core/models/payment-option.model';
import { PaymentService } from '../../../../core/services/payment.service';
import { paymentSteps, StepService } from '../../../../core/services/step.service';

@Component({
  selector: 'app-payment-option-item',
  templateUrl: './payment-option-item.component.html',
  styleUrls: ['./payment-option-item.component.scss']
})
export class PaymentOptionItemComponent implements OnInit {
  @Input() paymentOption: PaymentOptionModel = new PaymentOptionModel();
  icon = '';

  constructor(private paymentService: PaymentService, private stepService: StepService) {}

  ngOnInit() {
    const poCode = this.paymentOption.code.toLowerCase();
    const poSection = this.paymentOption.section.toLowerCase();
    if (this.paymentOption.payment_networks.length === 1) {
      const pnType = this.paymentOption.payment_networks[0].network_type.toLowerCase();
      this.icon = `assets/icons/payment-options/${poCode}-${poSection}-${pnType}-icon.svg`;
    } else {
      this.icon = `assets/icons/payment-options/${poCode}-${poSection}-icon.svg`;
    }
  }

  onClick() {
    if (this.paymentOption) {
      this.paymentService.selectedPaymentOption$$.next(this.paymentOption);
      if (this.paymentOption.payment_networks.length <= 1) {
        this.paymentService.selectedPaymentNetwork$$.next(this.paymentOption.payment_networks[0]);
        if (this.paymentOption.kyc_required) {
          this.stepService.toStep(paymentSteps.PAYMENT_KYC);
        } else {
          this.stepService.toStep(paymentSteps.PAYMENT_PROCESSOR);
        }
      } else {
        this.stepService.toStep(paymentSteps.PAYMENT_NETWORKS);
      }
    }
  }
}
