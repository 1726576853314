import { Component, OnDestroy, OnInit } from '@angular/core';
import { StepModel } from '../../../core/models/step.model';
import { StepService } from '../../../core/services/step.service';
import { Subscription } from 'rxjs';
import { UtilsService } from '../../../core/services/utils.service';

@Component({
  selector: 'app-previous-step',
  templateUrl: './previous-step.component.html',
  styleUrls: ['./previous-step.component.scss']
})
export class PreviousStepComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  currentStep: StepModel | undefined;
  isMobile: boolean = false;

  constructor(public stepService: StepService, private utilsService: UtilsService) {}

  ngOnInit() {
    // subscribe to step
    this.subscriptions.push(
      this.stepService.currentStep$$.subscribe((step: StepModel) => {
        if (step != null) {
          this.currentStep = step;
        }
      })
    );

    // subscribe to is mobile
    this.subscriptions.push(
      this.utilsService.isMobile$$.subscribe((isMobile) => {
        this.isMobile = isMobile;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onPreviousStepClick() {
    if (this.currentStep && !this.currentStep.isLocked) {
      this.stepService.toStep(this.currentStep.previousStep);
    }
  }
}
